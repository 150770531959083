import React, { useRef, useCallback, forwardRef, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  ListItem,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  ListItemText,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { useAuth } from 'src/contexts/AuthContext';
import { inviteFriend, changePassword, getInviteData } from 'src/actions/settingAction';


const UserBoxWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function UserBox() {

  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const dispatch = useDispatch()
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = React.useState(1);

  const [inviteItem, setInviteItem] = useState([]);

  const handleTab = (newValue) => {
    setValue(newValue);

  };

  const formatTimestampToDate = (timestamp) => {
    // Convert the ISO timestamp to a Date object
    const date = new Date(timestamp);

    // Format the date as YYYY.MM.DD
    const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;

    return formattedDate;
  }

  const handleClickOpen = () => {
    if (user) {
      setOpen(true);
    }
    getInviteData(user.id).then(res => {
      setInviteItem(res.white_list)
    }).catch(err => {
      console.log("error");
    })
  };

  const handleClose = () => {
    setOpen(false);
    setValue(1);
  }

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, t('passwordMinLength'))
      .required(t('currentPasswordRequired')),

    newPassword: Yup.string()
      .min(6, t('passwordMinLength'))
      .required(t('newPasswordRequired')),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('passwordsMustMatch'))
      .min(6, t('passwordMinLength'))
      .required(t('confirmPasswordRequired')),
  });

  const validationSchemaInvite = Yup.object().shape({
    inviteCode: Yup.string()
      .required(t('inviteCodeRequired')),
  });

  const handleSubmit = async (values) => {
    if (user) {
      const res = inviteFriend({ by_user: user.id, invite_code: values.inviteCode })
    }
  };

  const handlePasswordFormSubmit = async (values) => {
    if (user) {
      changePassword({
        old_password: values.currentPassword,
        new_password: values.newPassword
      })
    }
  }

  let str = "Hello, world!";

  return (
    <UserBoxWrapper >
      {user && (
        <Button
          fullWidth
          sx={{ pt: 0 }}
          onClick={handleClickOpen}
          startIcon={<Avatar
            variant="rounded"
            sx={{
              width: 50,
              height: 50,
              borderRadius: '50%',
              border: '1px solid #ababab'
            }}
            alt={user?.first_name}
            src={user?.avatar || user?.picture}
          //  src={user?.avatar}
          >{user?.first_name[0]}</Avatar>}>
          <div>
            <Typography sx={{ textAlign: 'left', color: 'black' }} fontSize={16} fontWeight={600}>{(user?.first_name + " " + user?.last_name)}</Typography>
            <Typography sx={{ textAlign: 'left', color: '#747778' }} fontSize={12} fontWeight={400}>{user?.email}</Typography>
          </div>
        </Button>
      )}
      <Dialog
        sx={{
          borderRadius: '0px !important',
          minWidth: '900px'
        }}
        onClose={handleClose} open={isOpen}>
        <DialogContent
          sx={{
            background: 'white',
            padding: 3
          }}>
          <Box mb={1}>
            <Typography fontWeight={700} fontSize={30} sx={{ color: 'black' }}>
              {t('settings')}
            </Typography>
          </Box>
          <Box
            sx={{ flexGrow: 1, color: 'black', display: 'flex', gap: 2, height: 320 }}
          >
            <Box width={180}>
              <Typography fontSize={20} sx={{ display: 'flex', alignItems: 'center' }}><SettingsIcon />&nbsp;{t('gerneral')}</Typography>
              <ListItem component="div" disablePadding sx={{ flexDirection: 'column', display: 'flex' }}>
                <ListItemButton onClick={() => handleTab(1)}>
                  <ListItemText primary={t('changePassword')} />
                </ListItemButton>
                <ListItemButton onClick={() => handleTab(2)}>
                  <ListItemText primary={t('enterInviteCode')} />
                </ListItemButton>
              </ListItem>
            </Box>
            {value === 1 ? (
              <Box width={370}>
                <Typography fontSize={20} fontWeight={700} mb={1}>
                  {t('changePassword')}
                </Typography>
                <Formik
                  initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handlePasswordFormSubmit}
                >
                  {({ handleChange, handleBlur }) => (
                    <Form>
                      <Field
                        size="small"
                        as={TextField}
                        type="password"
                        name="currentPassword"
                        label={t('currentPassword')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(<ErrorMessage name="currentPassword" />)}
                        helperText={<ErrorMessage name="currentPassword" />}
                        InputLabelProps={{
                          sx: {
                            color: '#2d333a !important', // Change label color
                          },
                        }}
                        sx={{
                          mb: 1.5,
                          '& .MuiInputBase-input': {
                            color: '#2d333a',
                            '&::placeholder': {
                              color: 'black',
                              opacity: 1,
                            },
                            '&:-webkit-autofill': {
                              WebkitBoxShadow: '0 0 0 100px #fff inset',
                              WebkitTextFillColor: 'gray',
                              caretColor: 'gray',
                              borderRadius: 'inherit',
                            },
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'gray !important',
                            },
                            '&:hover fieldset': {
                              borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#10a37f',
                            },
                          },
                        }}
                      />
                      <Field
                        size="small"
                        as={TextField}
                        type="password"
                        name="newPassword"
                        label={t('newPassword')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(<ErrorMessage name="newPassword" />)}
                        helperText={<ErrorMessage name="newPassword" />}
                        InputLabelProps={{
                          sx: {
                            color: '#2d333a !important', // Change label color
                          },
                        }}
                        sx={{
                          mb: 1.5,
                          '& .MuiInputBase-input': {
                            color: '#2d333a',
                            '&::placeholder': {
                              color: 'black',
                              opacity: 1,
                            },
                            '&:-webkit-autofill': {
                              WebkitBoxShadow: '0 0 0 100px #fff inset',
                              WebkitTextFillColor: 'gray',
                              caretColor: 'gray',
                              borderRadius: 'inherit',
                            },
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'gray !important',
                            },
                            '&:hover fieldset': {
                              borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#10a37f',
                            },
                          },
                        }}
                      />
                      <Field
                        size="small"
                        as={TextField}
                        type="password"
                        name="confirmPassword"
                        label={t('confirmPassword')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(<ErrorMessage name="confirmPassword" />)}
                        helperText={<ErrorMessage name="confirmPassword" />}
                        InputLabelProps={{
                          sx: {
                            color: '#2d333a !important', // Change label color
                          },
                        }}
                        sx={{
                          mb: 1.5,
                          '& .MuiInputBase-input': {
                            color: '#2d333a',
                            '&::placeholder': {
                              color: 'black',
                              opacity: 1,
                            },
                            '&:-webkit-autofill': {
                              WebkitBoxShadow: '0 0 0 100px #fff inset',
                              WebkitTextFillColor: 'gray',
                              caretColor: 'gray',
                              borderRadius: 'inherit',
                            },
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'gray !important',
                            },
                            '&:hover fieldset': {
                              borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#10a37f',
                            },
                          },
                        }}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ background: '#10a37f', width: '100%', mb: 2, backgroundColor: '#265A9E', color: 'white' }}
                      >
                        {t('changePassword')}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            ) : (
              <Box width={370}>
                <Typography fontSize={20} fontWeight={700} mb={1}>
                  {t('enterInviteCode')}
                </Typography>
                <Typography mb={1.5}>
                  {t('enterInviteCodeDescription')}
                </Typography>
                <Formik
                  initialValues={{ inviteCode: '' }}
                  validationSchema={validationSchemaInvite}
                  onSubmit={handleSubmit}
                >
                  {({ handleChange, handleBlur }) => (
                    <Form>
                      <Field
                        size="small"
                        as={TextField}
                        type="text"
                        name="inviteCode"
                        label={t('inviteCode')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(<ErrorMessage name="inviteCode" />)}
                        helperText={<ErrorMessage name="inviteCode" />}
                        InputLabelProps={{
                          sx: {
                            color: '#2d333a !important', // Change label color
                          },
                        }}
                        sx={{
                          mb: 0.5,
                          '& .MuiInputBase-input': {
                            color: '#2d333a',
                            '&::placeholder': {
                              color: 'black',
                              opacity: 1,
                            },
                            '&:-webkit-autofill': {
                              WebkitBoxShadow: '0 0 0 100px #fff inset',
                              WebkitTextFillColor: 'gray',
                              caretColor: 'gray',
                              borderRadius: 'inherit',
                            },
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'gray !important',
                            },
                            '&:hover fieldset': {
                              borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#10a37f',
                            },
                          },
                        }}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ background: '#10a37f', width: '100%', backgroundColor: '#265A9E', color: 'white' }}
                      >
                        {t('applyCode')}
                      </Button>
                    </Form>
                  )}
                </Formik>
                {inviteItem.length > 0 && (
                  <Box pt={1} mb={1} >
                    <TableContainer sx={{ height: "100px", overflowY: 'auto' }}>
                      <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                        <TableHead>
                        </TableHead>
                        <TableRow sx={{
                          color: 'red'
                        }}>
                          <TableCell sx={{ color: 'black', py: 0.5, pl: 0 }}>{t('accountOrCoupon')}</TableCell>
                          <TableCell sx={{ color: 'black', py: 0.5 }} align="left">{t('appliedOn')}</TableCell>
                          <TableCell sx={{ color: 'black', py: 0.5, pr: 0 }} align="right">{t('received')}</TableCell>
                        </TableRow>
                        {/* <TableBody> */}
                        {inviteItem.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}
                          >
                            <TableCell align='left' sx={{ fontSize: 12, py: 0.5, pl: 0, color: '#F79E1B' }}>{row.email}</TableCell>
                            <TableCell align="left" sx={{ fontSize: 12, py: 0.5 }}>{formatTimestampToDate(row.date)}</TableCell>
                            <TableCell align="right" sx={{ fontSize: 12, py: 0.5, pr: 0 }}>1 {t('credit')}</TableCell>
                          </TableRow>
                        ))}
                        {/* </TableBody> */}
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>

    </UserBoxWrapper>
  );
}

