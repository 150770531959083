import React from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
} from '@mui/material';

import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'src/contexts/AuthContext';

function VerifyCode({ switchForm }) {

  const { t } = useTranslation();

  const { sendVerifyCode, email, sendEmail } = useAuth();

  const validationSchema = Yup.object({
    code: Yup.array()
      .of(
        Yup.string()
          .matches(/^\d$/, t('codeDigitError'))
          .required(t('codeRequired'))
      )
      .length(4, t('codeLengthError')),
  });

  const handleSubmit = async (values , { setSubmitting }) => {
    const code = values.code.join('');
    console.log("Verification Code:", parseInt(code));
    try {
      sendVerifyCode({email : email, otp_code : parseInt(code)}).then(res => {
        if (res) {
          switchForm('resetPassword');
        }
     });
    } finally {
      setSubmitting(false);
    }
  };

  const resendEmail = () => {
    sendEmail(email)
  }

  return (
    <>
      <Typography mb={2} fontWeight={700} fontSize={24} sx={{ color: '#2d333a' }}>
        {t('verifyCode')}
      </Typography>
      <Formik
        initialValues={{ code: ['', '', '', ''] }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, submitCount }) => (
          <Form>
            <Box display="flex" justifyContent="space-between" m={2}>
              <FieldArray name="code">
                {() => (
                  Array.from({ length: 4 }).map((_, index) => (
                    <TextField
                      key={index}
                      inputRef={(input) => values[`inputRef${index}`] = input}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                        onPaste: (e) => {
                          e.preventDefault();
                          const pasteData = e.clipboardData.getData('text').slice(0, 4);
                          pasteData.split('').forEach((char, idx) => {
                            if (idx < 4) {
                              setFieldValue(`code[${idx}]`, char);
                              if (values[`inputRef${idx + 1}`]) {
                                values[`inputRef${idx + 1}`].focus();
                              }
                            }
                          });
                        },
                      }}
                      size="normal"
                      variant="outlined"
                      error={!values.code[index] && submitCount > 0}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d$/.test(value)) {
                          setFieldValue(`code[${index}]`, value);
                          if (values[`inputRef${index + 1}`]) {
                            values[`inputRef${index + 1}`].focus();
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !values.code[index] && index > 0) {
                          if (values[`inputRef${index - 1}`]) {
                            values[`inputRef${index - 1}`].focus();
                          }
                        }
                      }}
                      sx={{
                        flexBasis: '18%',
                        '& .MuiInputBase-input': {
                          color: 'black',
                          fontSize: 18,
                          '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px #fff inset',
                            WebkitTextFillColor: 'gray',
                            caretColor: 'gray',
                            borderRadius: 'inherit',
                          },
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: !values.code[index] && submitCount > 0 ? 'red' : 'gray',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#10a37f',
                          },
                        },
                      }}
                    />
                  ))
                )}
              </FieldArray>
            </Box>

            <Button
              size='large'
              sx={{ width: '100%', mb: 1, color: '#10a37f' }}
              onClick={resendEmail}
            >
              {t('resendEmail')}
            </Button>

            <Button
              type="submit"
              size='large'
              variant="contained"
              sx={{ background: '#10a37f', width: '100%', color: 'white' }}
            >
              {t('continue')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default VerifyCode;
