import React from 'react';
import { Button, TextField, Typography } from '@mui/material';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAuth } from 'src/contexts/AuthContext';

function SendEmail({ switchForm }) {

  const { t } = useTranslation();

  const { sendEmail } = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string().email(t('invalidEmail')).required(t('emailRequired')),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {

      sendEmail(values.email).then(res => {
        if (res) {
          switchForm('verifyCode');
        }
     });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Typography mb={2} fontWeight={700} fontSize={24} sx={{ color: '#2d333a' }}>
        {t('sendEmail')}
      </Typography>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              as={TextField}
              name="email"
              label={t('emailAddress')}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                sx: { color: '#2d333a !important' },
              }}
              sx={{
                mb: 1.5,
                '& .MuiInputBase-input': {
                  color: '#2d333a',
                  '&::placeholder': {
                    color: 'black',
                    opacity: 1,
                  },
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 100px #fff inset',
                    WebkitTextFillColor: 'gray',
                    caretColor: 'gray',
                    borderRadius: 'inherit',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray !important',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#10a37f',
                  },
                },
              }}
              helperText={<ErrorMessage name="email" />}
              error={!!<ErrorMessage name="email" />}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ background: '#10a37f', width: '100%', color: 'white' }}
              disabled={isSubmitting}
            >
              {t('continue')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SendEmail;
