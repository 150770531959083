import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/contexts/AuthContext';

function ResetPassword({ modalClose }) {

  const { resetPassword, email } = useAuth();
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, t('passwordMinLength'))
      .required(t('passwordRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('passwordsMustMatch'))
      .required(t('confirmPasswordRequired')),
  });

  const handleSubmit = async (values) => {
    const data = {
      email : email,
      password: values.password,
    };
    resetPassword(data).then(res => {
      if(res){
        modalClose();
      }
    })
  };

  const sharedTextFieldStyles = {
    mb: 1.5,
    '& .MuiInputLabel-root': {
      color: '#2d333a !important',
    },
    '& .MuiInputBase-input': {
      color: '#2d333a',
      '&::placeholder': {
        color: 'black',
        opacity: 1,
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #fff inset',
        WebkitTextFillColor: 'gray',
        caretColor: 'gray',
        borderRadius: 'inherit',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray !important',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#10a37f',
      },
    },
  };

  return (
    <>
      <Typography mb={2} fontWeight={700} fontSize={24} sx={{ color: '#2d333a' }}>
        {t('resetPassword')}
      </Typography>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur }) => (
          <Form>
            {['password', 'confirmPassword'].map((field, index) => (
              <Field
                key={index}
                type="password"
                as={TextField}
                name={field}
                label={t(field === 'password' ? 'newPassword' : 'confirmPassword')}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(<ErrorMessage name={field} />)}
                helperText={<ErrorMessage name={field} />}
                InputLabelProps={{
                  sx: {
                    color: '#2d333a !important',
                  },
                }}
                sx={sharedTextFieldStyles}
              />
            ))}
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ background: '#10a37f', width: '100%', color: 'white' }}
            >
              {t('resetPassword')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ResetPassword;
