import { Box } from '@mui/material';
import Invite from './Invite';
import TopUp from './TopUp';

import { useAuth } from 'src/contexts/AuthContext';

function HeaderButtons() {
  const { user } = useAuth();
  return (
    <Box  sx={{ mr: 1, display:'flex' }}>
      <Box
        sx={{
          display: {
            xs: 'none', // Hidden on small screens
            md: 'block', // Shown on medium and larger screens
          },
        }}
      >
        {user && (
          <TopUp />
        )}
      </Box>
      {user && (
        <Invite />
      )}
    </Box>
  );
}

export default HeaderButtons;
