import { useRef, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Divider,
  InputBase
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { topUp, checkPaymentStatus } from 'src/actions/paymentAction';
import { useAuth } from 'src/contexts/AuthContext';

function TopUp() {

  const { user } = useAuth();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.setting.language);
  const [isOpen, setOpen] = useState(false);
  const [spendAmount, setSpendAmount] = useState(0);
  const [getAmount, setGetAmount] = useState(0);
  const [searchParams] = useSearchParams();
  const [isApplyCredits, setIsApplycredits] = useState(false);
  const token = searchParams.get("token");
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSpendAmount(0);
    setGetAmount(0);
    setIsApplycredits(false);
  };

  useEffect(() => {
    if (token) {
      checkPaymentStatus(token);
    }
  }, [token]);

  const BalanceLabel = () => (
    <span style={{ color: '#EA0004' }}>
      ${user ? parseFloat(user?.balance).toFixed(2) : '0.00'}&nbsp;
    </span>
  );

  const ButtonContent = (lang) => {
    const balanceContent = (
      <>
        <BalanceLabel />
        <span>{t('left')} <br /> {t('pleaseTopUp')}</span>
      </>
    );

    const translations = {
      en: balanceContent,
      cn: (
        <>
          <span>剩余</span>&nbsp;<BalanceLabel /><br /><span>请充值</span>
        </>
      ),
      hk: (
        <>
          <span>剩餘</span>&nbsp;<BalanceLabel /><br /><span>請充值</span>
        </>
      ),
      ja: (
        <>
          <span>残高</span>&nbsp;<BalanceLabel /><br /><span>チャージ</span>
        </>
      ),
    };

    return translations[lang] || balanceContent;
  };

  const amountHandle = (event) => {
    if(!isApplyCredits){
      setGetAmount(event.target.value);
    }
    setSpendAmount(event.target.value)
  }

  const handlePayment = async () => {
    if (spendAmount > 0) {
      const spend_amount = parseFloat(spendAmount).toFixed(2).toString();
      const get_amount = parseFloat(getAmount).toFixed(2).toString();
      topUp({ user_id: user.id, amount: spend_amount, get_amount : get_amount, is_credits : isApplyCredits })
    }
  };

  const applyCredits = () => {
    setSpendAmount(2);
    setGetAmount(5);
    setIsApplycredits(!isApplyCredits)
  }

  return (
    <>
      {user && (
        <Button
          disabled={!user}
          sx={{
            display: 'block',
            color: '#265A9E',
            lineHeight: '18px',
            fontSize: { xs: 12, md: 14 },
            ml: { xs: 0, md: '20px' },
            padding: { xs: '0px', md: '5px 10px' },
            borderRadius: '20px',
            border: { xs: 'none', md: '2px solid #378DFD' },
          }}
          ref={ref}
          onClick={handleOpen}
        >
          {ButtonContent(currentLang)}
        </Button>
      )}

      <Dialog
        maxWidth='xs'
        sx={{ borderRadius: '0px !important' }}
        onClose={handleClose} 
        open={isOpen}
      >
        <DialogContent sx={{ background: 'white', padding: 3, color: 'black' }}>
          <Typography color="black" fontSize={18} fontWeight={700} variant="subtitle1" mb={1}>
            {t('paymentOptions')}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ width: "50%", display: 'flex', pr: 2, alignItems: 'center' }}>
              <Typography><b>{t('spend')}:</b> &nbsp;$</Typography>
              <InputBase
                disabled={isApplyCredits}
                sx={{
                  flex: 1, ml: "3px", color: 'black', border: "1px solid black", padding: "0px 10px", width: 50,
                  '& input[type=number]': { appearance: "none" },
                }}
                inputProps={{ min: 0 }}
                type="number"
                value={spendAmount}
                onChange={amountHandle}
              />
            </Box>
            <Box sx={{ width: "50%", display: 'flex', px: 2, alignItems: 'center' }}>
              <Typography><b>{t('get')}:</b> &nbsp;$</Typography>
              <InputBase
                disabled
                sx={{
                  '&.Mui-disabled': {
                    '& .MuiSelect-select': {
                      WebkitTextFillColor: 'gray',
                      color:'red'
                    },
                  },
                  flex: 1, ml: "3px", color: 'black', border: "1px solid black", padding: "0px 10px", width: 50,
                  '& input[type=number]': { appearance: "none" },
                }}
                inputProps={{ min: 0 }}
                type="number"
                value={getAmount}
              />
            </Box>
          </Box>

          <Button
            variant="outlined"
            sx={{
              marginY: 1,
              borderRadius: 20,
              padding: '2px 20px',
              border: '1px solid black',
              fontSize: 14,
              textTransform: "none",
            }}
            onClick={handlePayment}
          >
            {t('topUp')}
          </Button>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography><b>{t('discountCredits')}:&nbsp;&nbsp; {user?.credits}</b></Typography>
            {user?.credits > 0 && (
              <Button
                onClick={applyCredits}
                variant="outlined"
                sx={{
                  ml: 4,
                  borderRadius: 20,
                  padding: '2px 20px',
                  border: '1px solid #F79E1B',
                  fontSize: 14,
                  color: '#F79E1B',
                  textTransform: "none",
                }}
              >
                {t('use60Off')}
              </Button>
            )}
          </Box>

          <Divider sx={{ marginY: 1 }} />

          <Typography
            fontWeight={700} fontSize={14} sx={{ mb: 1, color: '#265A9E' }}
          >
            {t('inviteFriendOff')}
          </Typography>
          <Box
            component="img"
            src="/static/images/payment.png" // Replace with your image URL
            alt="payment"
            sx={{
              width: "100%", maxWidth: "300px", height: "auto", borderRadius: "8px"
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TopUp;
