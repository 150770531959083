import axios from "axios";


export const topUp = async (data) => {
   let url = `${process.env.REACT_APP_BACKEND_URL}/api/payment/create_payment/`;
   try {
     const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });
     const paymentUrl = response.data.url;
     window.location.href = paymentUrl; // Redirect to HitPay's payment page
   } catch (error) {
     console.error("Payment initiation failed", error);
   }
};

export const checkPaymentStatus = async (token) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/api/payment/check_payment_status/`;
  try {
    const response = await axios.get(`${url}`,{token: token}, {
     headers: {
       "Content-Type": "application/json",
       Accept: "application/json"
     }
   });
    const paymentUrl = response.data.url;
    window.location.href = paymentUrl; // Redirect to HitPay's payment page
  } catch (error) {
    console.error("Payment initiation failed", error);
  }
} 

