

import {
   SET_MODEL,
   TOOGLE_LANAUAGE,
   SET_LANGUAGE,
} from './types';

import swal from 'sweetalert2';
import i18next from 'i18next'; 

export const selectModel = (model) => (dispatch) => {
  dispatch({
    type: SET_MODEL,
    payload : { model }
  });
};



export const toogleLanguage = (val) => (dispatch) => {
  dispatch({
    type: TOOGLE_LANAUAGE,
    payload : { val }
  });
};

export const setLanguage = (lang) => (dispatch) => {
  dispatch({
    type : SET_LANGUAGE,
    payload : {lang}
  })
}

export const getInviteCode = async () => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/get_invite_code/`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
  });
  const data = await response.json();
  localStorage.setItem('invite_code', data.invite_code);
  return data.invite_code;
};

export const inviteFriend = async (inviteData) =>  {

  const {invite_code} = inviteData;
  const real_code = localStorage.getItem('invite_code');
  if(invite_code != real_code){
    swal.fire({
      title: i18next.t("Invite Code is not correct!"),
      icon: "error",
      toast: true,
      timer: 6000,
      position: 'bottom-right',
      timerProgressBar: true,
      showConfirmButton: false
    });

    return false;
  }

  let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/register_invite_code/`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(inviteData)
  });
  const data = await response.json();
  // setLoading(false);
  if (response.status === 201 || response.status === 200) {
    swal.fire({
      title: i18next.t("Invite Friend Success!"),
      icon: "success",
      toast: true,
      timer: 6000,
      position: 'bottom-right',
      timerProgressBar: true,
      showConfirmButton: false
    });
    return data;
  } else {
    console.log("An Error Occured");
    swal.fire({
      title: i18next.t("No Account Found"),
      icon: "error",
      toast: true,
      timer: 6000,
      position: 'bottom-right',
      timerProgressBar: true,
      showConfirmButton: false
    });
    return false
  }
};

export const changePassword = async (passData) => {

  let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/change_password/`;
  
  // Assuming you have stored the token in local storage after user login
  const token = localStorage.getItem('access'); // or wherever you store your token
  
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}` // Include the token in the Authorization header
    },
    body: JSON.stringify(passData)
  });
  
  const data = await response.json();
  console.log('data', data)
  // setLoading(false);
  if (response.status === 201 || response.status === 200) {
    swal.fire({
      title: i18next.t("Change Password Success!"),
      icon: "success",
      toast: true,
      timer: 6000,
      position: 'bottom-right',
      timerProgressBar: true,
      showConfirmButton: false,
      customClass: {
        popup: 'my-toast-class' // Custom class name
      }
    });
    return data;
  } else {
    console.log("An Error Occured");
    swal.fire({
      title: i18next.t("Current Password isn't correct!"),
      icon: "error",
      toast: true,
      timer: 600000,
      position: 'bottom-right',
      timerProgressBar: true,
      showConfirmButton: false,
      customClass: {
        popup: 'my-toast-class' // Custom class name
      }
    });
    return false;
  }
};

export const getInviteData = async (user_id) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/get_invite_item/`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({user_id})
  });
  const data = await response.json();
  console.log("data", data);
  // setLoading(false);
  if (response.status === 201 || response.status === 200) {
    return data;
  } else {
    console.log("An Error Occured");
    return false
  }
}
