export const SET_MESSAGES = 'SET_MESSAGES';
export const ADD_SUB_MESSAGE = 'ADD_SUB_MESSAGE';
export const SET_ACTIVECHAT_ID = 'SET_CHAT_ID';
export const SET_NEW_ACTIVECHAT_ID = 'SET_NEW_ACTIVECHAT_ID';
export const SET_INCREASE_CHAT_ID = 'SET_INCREASE_CHAT_ID';
export const DELETE_CHAT = 'DELETE_CHAT';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_MODEL = 'SET_MODEL';
export const TOOGLE_LANAUAGE = 'TOOGLE_LANAUAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_CHAT_LOG = 'SET_CHAT_LOG';
export const CLEAN_CHAT_CONTENT = 'CLEAN_CHAT_CONTENT';

export const REST_USER = 'REST_USER';
