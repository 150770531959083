import {
  SET_MESSAGES,
  ADD_SUB_MESSAGE,
  SET_INCREASE_CHAT_ID,
  SET_ACTIVECHAT_ID,
  SET_NEW_ACTIVECHAT_ID,
  DELETE_CHAT,
  SET_CHAT_LOG,
  CLEAN_CHAT_CONTENT
} from 'src/actions/types';

const initialState = {
  messages: localStorage.getItem('chatHistory')
    ? JSON.parse(localStorage.getItem('chatHistory'))[0].messages
    : [],
  activeChatId: null, // indicates the id of the selected chat from chat history,
  chatHistory: localStorage.getItem('chatHistory')
    ? JSON.parse(localStorage.getItem('chatHistory'))
    : [],

  userChatLog : [],
  selectedChatLog : [],
};

const updateChatHistory = (
  chatHistory,
  activeChatId,
  newMessage,
  isSubMessage = false
) => {
  return chatHistory.map((chat) => {
    if (chat.id === activeChatId) {
      const messages = isSubMessage
        ? [...chat.messages.slice(0, -1), newMessage]
        : [...chat.messages, newMessage];
      return { ...chat, messages };
    }
    return chat;
  });
};

const saveToLocalStorage = (data) => {
  localStorage.setItem('chatHistory', JSON.stringify(data));
};

const chatReducer = (state = initialState, action) => {
  const chatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];

  switch (action.type) {
    case SET_MESSAGES: {
      const { newMessage } = action.payload;
      
      // const updatedMessages =
      //   chatHistory.length > 0
      //     ? updateChatHistory(chatHistory, state.activeChatId, newMessage)
      //     : [
      //         {
      //           id: state.activeChatId,
      //           date: Date.now(),
      //           messages: [newMessage]
      //         }
      //       ];

      // saveToLocalStorage(updatedMessages);

      // return {
      //   ...state,
      //   messages: [...state.messages, newMessage],
      //   chatHistory: updatedMessages
      // };

        return {
          ...state,
          selectedChatLog : [...state.selectedChatLog, newMessage]
        }
    }

    case ADD_SUB_MESSAGE: {
      const { message } = action.payload;
      const lastMessage = state.selectedChatLog[state.selectedChatLog.length - 1];
      
      const updatedLastMessage = {
        ...lastMessage,
        content: lastMessage.content + message.content
      };
      return {
        ...state,
        selectedChatLog : [...state.selectedChatLog.slice(0, -1), updatedLastMessage],
        // messages: [...state.messages.slice(0, -1), updatedLastMessage],
        // chatHistory: updatedMessages
      };
    }

    case SET_INCREASE_CHAT_ID:

      // localStorage.removeItem('chatHistory');
      // console.log("ssss", chatHistory, localStorage.getItem('chatHistory'));
      return {
        ...state,
        messages : [],
        chatHistory: [],
        selectedChatLog : [],
        activeChatId : null,
      }
      const newChatId = chatHistory.length > 0 ? chatHistory.length : 0;
      if(chatHistory.length > 0){
        const lastChatMessage = chatHistory[(chatHistory.length - 1)].messages
        if( lastChatMessage.length == 0 ){
          console.log("last", lastChatMessage.length);
          return {
            ...state
          }
        }
      } 

      // Create a new chat object with the new ID and an empty messages array
      const newChat = {
        id: newChatId,
        date: "Date.now()",
        messages: []
      };

      // Add the new chat to the chat history
      const updatedChatHistory = [...chatHistory, newChat];

      // Save the updated chat history to local storage
      saveToLocalStorage(updatedChatHistory);

      return {
        ...state,
        activeChatId: newChatId,
        messages: [],
        chatHistory: updatedChatHistory
      };

    case SET_ACTIVECHAT_ID:
      const chatId = action.payload.chatId;
      const activeChat = action.payload.activeChat;
      const selected_item = state.userChatLog.find(item => item.id === chatId);
      return {
        ...state,
        activeChatId: action.payload.chatId,
        // messages : userChatLog[chatId].messages
        selectedChatLog : activeChat.formatted_messages,
      };
    
    case SET_NEW_ACTIVECHAT_ID:
      return {
        ...state,
        activeChatId: action.payload.chatId,
      };
    
    case DELETE_CHAT:
      const { chat_id } = action.payload;
      return {
        ...state,
        activeChatId : state.activeChatId === chat_id ? null : state.activeChatId,
        selectedChatLog : state.activeChatId === chat_id ? [] : state.selectedChatLog
      }
      const updated_chatHistory = state.chatHistory.filter(
        (chat) => chat.id !== chat_id
      );

      // saveToLocalStorage(updated_chatHistory);

      return {
        ...state,
        chatHistory: updated_chatHistory
      };
    
    case CLEAN_CHAT_CONTENT:
      return {
        ...state,
        selectedChatLog : []
      }

    case SET_CHAT_LOG :
        return {
          ...state,
          userChatLog : action.payload.chat_log
        }
    default:
      return state;
  }
};

export default chatReducer;
